<template>
  <v-container style="padding-top: 20px">
    <div class="title-wrapper">
      <h3 class="page-title">{{ lg_meeting_recording.title }}</h3>
      <!-- <h4 class="page-sub-title">{{ lg_meeting_recording.search_conditions }}</h4> -->
    </div>
    <!-- vertical-top-row px-5   use for top position in table is class after elevation-1-->
    <v-data-table
      :headers="lg_meeting_recording.headers"
      :items="reports"
      class="elevation-1"
      :loading="loading"
      :items-per-page="itemsPerPage"
    >
      <template v-slot:top>
        <v-row>
          <v-menu
            v-model="dateMenuVisible"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
              class="mx-4"
                :value="renderDateSearch"
                clearable
                :label="lg_meeting_recording.search_date"
                readonly
                v-bind="attrs"
                v-on="on"
                @click:clear="dateSearch = null"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateSearch"
              @change="dateMenuVisible = false"
            ></v-date-picker>
          </v-menu>
          
          <v-text-field
            v-model="topicSearch"
            class="mx-4"
            :label="lg_meeting_recording.search_topic"
            :clearable="true"
          ></v-text-field>
         
        </v-row>
      </template>

      <template v-slot:item.options="{ item }">
        <p
          v-for="(o, i) in item.options"
          :key="`${o.id}-${i}`"
          style="margin: 0"
        >
          <span class="mr-2">{{ i + 1 }}.</span>
          <span class="mr-2">{{ o.value }}</span> ( คะแนน {{ o.answers }} )
        </p>
      </template>

      <template v-slot:item.answerMembers="{ item }">
        <p
          v-for="(m, i) in item.answerMembers"
          :key="`member-${i}`"
          style="margin: 0"
        >
          <span class="mr-2">{{ i + 1 }}.</span>
          {{ m }}
        </p>
      </template>

      <template v-slot:item.action="{ item }">
        <v-btn
            color="#3E73BB"
            class="rounded-pill"
            dark
           @click="Downloaditemrecording(item)"
            style="margin: auto;"
          >
          Download
          </v-btn>
      </template>

      <template v-slot:item.index="{ item,index }">
        {{ index+1}}
      </template>

      <template v-slot:item.started_time="{ item }">
        {{ new Date(item.started_time).toLocaleString('en-US', { timeZone: "Asia/Bangkok" })  }}
      </template>

      <template v-slot:item.stopped_time="{ item }">
        {{ new Date(item.stopped_time).toLocaleString('en-US', { timeZone: "Asia/Bangkok" })  }}
      </template>

      <template v-slot:item.createdAt="{ item }">
        {{ item.createdAt | dateFormat }}
      </template>
    </v-data-table>
    <p>{{ dateSearch }}</p>
    <template>
      <VotePdfDialog
        v-if="setdatavote"
        :datavote="setdatavote"
        :visible="pdfDialog"
        :key="setdatavoteshow"
        @on-cancel="pdfDialog = false"
      />
    </template>
    <ListRoom :style="{ display: 'none' }" ref="listroom" />
  </v-container>
</template>

<script>
import firebase from "firebase/app";
import api from "@/services/api";
import { format, parseISO } from "date-fns";
import VotePdfDialog from "@/components/core/VotePdfDialog";
import ListRoom from "@/views/ListRoom2.vue";
import { server ,apiUrlRecord} from "../services/constants";
import { feature } from "../services/constants";
import { lg_meeting_recording } from "./language/lg_meeting_recording";

export default {
  data: () => ({
    itemsPerPage: 5,
    setdatavoteshow: 0,
    votes: [],
    setdatavote: "",
    topicSearch: null,
    dateMenuVisible: false,
    dateSearch: null,
    loading: false,
    pdfDialog: false,
    pdfBytes: null,
    userRole: null,
    company_id: null,
  }),
  components: {
    VotePdfDialog,
    ListRoom,
  },
  computed: {
    renderDateSearch() {
      return this.dateSearch
        ? format(parseISO(this.dateSearch), "dd/MM/yyyy")
        : "";
    },
    reports() {
      const { votes, topicSearch, dateSearch } = this;
      const reports = votes.filter((v) => {
        const { createdBy, topic, createdAt,meeting,started_time } = v;
        const validTopic =
          topicSearch?.length > 0
            ? meeting.title?.toLowerCase()?.includes(topicSearch?.toLowerCase())
            : true;

        const validDate = dateSearch
          ?  new Date(started_time).toLocaleDateString('en-CA') &&
          new Date(started_time).toLocaleDateString('en-CA') ===
          dateSearch
          : true;
        return validTopic && validDate;
      });

      return reports;
    },
  },
  methods: {
  async  downloadFile(url, filename) {
      try {
        const response = await fetch(url);
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        
        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);
        
        const anchor = document.createElement('a');
        anchor.href = blobUrl;
        anchor.download = filename || 'video.mp4';
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        
        // Release memory
        URL.revokeObjectURL(blobUrl);
    } catch (error) {
        console.error('Error downloading file:', error);
    }
    },
    async Downloaditemrecording(item) {
       console.log(item);
      //  window.location.replace(item.download_url);
      // window.location.replace(`${apiUrlRecord}/${item.output_file_name}`);
        this.downloadFile(`${apiUrlRecord}/${item.output_file_name}`, `${item.output_file_name}`);
      
    },
    async fetchMembers() {
      const snapshot = await firebase.firestore().collection("members").get();
      const { docs } = snapshot;
      return docs.map((d) => d.data());
    },
    async fetchVotes() {
      try {
        this.loading = true;
        let result;
        let role = localStorage.getItem(server.ROLE);
        let userId = localStorage.getItem(server.USER_ID)?localStorage.getItem(server.USER_ID):0;
        if (role == "Admin") {
          result= await api.getMeetingRecordall();
        }else{
          result= await api.getMeetingRecordbyid(userId);
        }

        this.votes = result.data;
       
        this.loading = false;
      } catch (error) {
        this.loading = false;

        this.$swal.fire("Error", error.message, "error");
      }
    },
  },
  beforeCreate() {
    localStorage.getItem(feature.LANGUAGE) === "th"
      ? (this.lg_meeting_recording = lg_meeting_recording.meeting_recording_th)
      : (this.lg_meeting_recording = lg_meeting_recording.meeting_recording_en);
  },
  async created() {
    if (api.isLoggedIn()) {
      this.fetchVotes();
    }
  },
};
</script>

<style>
.vertical-top-row > .v-data-table__wrapper > table > tbody > tr {
  vertical-align: text-top;
}

.title-wrapper {
  text-align: center;
  margin-bottom: 20px;
}

.page-title {
  font-size: 20px;
  margin-bottom: 10px;
}

.page-sub-title {
  font-weight: 400;
}
/*
.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center, .apexcharts-legend.apx-legend-position-top.apexcharts-align-center {
    display: block;
}
.apexcharts-xaxis-title{
  font-size:12px;
}
*/
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 85px;
}
</style>
